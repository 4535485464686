* {
  box-sizing: border-box;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
  background-color: var(--surface-top);
}

.transparent-mode {
  background-color: transparent !important;
}

ion-content {
  --background: transparent;
}

body {
  margin: 0;
}

:root {
  --navbar-height: 50px;

  --yellow-500: #eddd2e;

  --neutral-0: #ffffff;
  --neutral-100: #f9f9f9;
  --neutral-200: #ebecec;
  --neutral-300: #cbcdd1;
  --neutral-400: #a0a2a6;
  --neutral-500: #83838d;
  --neutral-600: #484751;
  --neutral-700: #26272c;
  --neutral-800: #1c1d22;
  --neutral-900: #0f0f10;
  --neutral-950: #090909;
  --neutral-1000: #000000;

  --blue-0: #e3eeff;
  --blue-100: #bdd7ff;
  --blue-200: #94beff;
  --blue-300: #68a1f8;
  --blue-400: #4b92fd;
  --blue-500: #0c69f5;
  --blue-600: #0450c5;
  --blue-700: #023e9a;
  --blue-800: #002b6d;
  --blue-900: #0d1c32;
  --blue-1000: #0d1520;

  --orange-0: #f8d7bd;
  --orange-100: #ffcfa7;
  --orange-200: #ffc18e;
  --orange-300: #f8a159;
  --orange-400: #ff943d;
  --orange-500: #df5f10;
  --orange-600: #b84d0b;
  --orange-700: #8e3803;
  --orange-800: #572302;
  --orange-900: #311300;
  --orange-1000: #1f0c00;

  --red-0: #ffe1e1;
  --red-100: #ffbebe;
  --red-200: #ff9797;
  --red-300: #f47a7a;
  --red-400: #ea5e5e;
  --red-500: #d33f3f;
  --red-600: #a62828;
  --red-700: #831515;
  --red-800: #5c0b0b;
  --red-900: #3c0707;
  --red-1000: #270707;

  --green-0: #e2ffdb;
  --green-100: #c8f7bc;
  --green-200: #b4ffa1;
  --green-300: #96f77d;
  --green-400: #77e25c;
  --green-500: #4ccf2b;
  --green-600: #3a9e21;
  --green-700: #24750f;
  --green-800: #185a07;
  --green-900: #0b2b02;
  --green-1000: #071703;

  --surface-base: var(--neutral-0);
  --surface-lower: var(--neutral-100);
  --surface-raised: var(--neutral-0);
  --surface-top: var(--neutral-0);
  --surface-top-border: var(--neutral-200);

  --dialog-background: var(--neutral-0);

  --table-background: var(--neutral-100);
  --table-background-highlight: var(--blue-0);
  --table-background-holiday: var(--red-0);
  --table-background-weekend: var(--neutral-200);
  --table-background-ooo: var(--neutral-200);
  --table-header-background: var(--neutral-200);
  --table-header-highlight: var(--blue-500);
  --table-header-holiday: var(--red-500);
  --table-header-weekend: var(--neutral-200);
  --table-header-weekend-background: var(--neutral-200);
  --table-border-color: var(--neutral-300);

  --table-booking-background: var(--blue-500);
  --table-booking-text: var(--neutral-0);
  --table-booking-border: var(--blue-600);

  --panel-shadow: var(--neutral-300);

  --primary-button-background: var(--blue-500);
  --primary-button-text: var(--neutral-0);
  --primary-button-background-hover: var(--blue-600);

  --disabled-button-background: var(--neutral-300);
  --disabled-button-text: var(--neutral-500);
  --disabled-button-border: var(--neutral-400);

  --delete-button-background: var(--red-500);
  --delete-button-background-hover: var(--red-600);
  --delete-button-border: var(--red-500);
  --delete-button-text: var(--neutral-100);
  --delete-button-text-hover: var(--neutral-100);

  --delete-secondary-button-background: none;
  --delete-secondary-button-background-hover: var(--red-500);
  --delete-secondary-button-border: var(--red-500);
  --delete-secondary-button-text: var(--red-500);
  --delete-secondary-button-text-hover: var(--neutral-100);

  --secondary-button-background: none;
  --secondary-button-background-hover: var(--blue-500);
  --secondary-button-text: var(--blue-500);
  --secondary-button-text-hover: var(--neutral-100);
  --secondary-button-border: var(--blue-500);

  --text-highlight: var(--neutral-1000);
  --text-base: var(--neutral-900);
  --text-secondary: var(--neutral-800);
  --text-muted: var(--neutral-700);
  --text-disabled: var(--neutral-500);
  --text-muted-disabled: var(--neutral-400);

  --header-background: var(--neutral-700);

  --input-background: var(--neutral-0);
  --input-background-disabled: var(--neutral-200);
  --input-background-error: var(--red-100);
  --input-border: var(--neutral-300);
  --input-border-disabled: var(--neutral-500);

  --admin-header-background: var(--neutral-0);
  --admin-header-border: var(--neutral-300);
  --admin-background: var(--neutral-100);

  --room-panel-background: var(--neutral-0);
  --room-panel-background-hover: var(--blue-0);
  --room-panel-background-succeed: var(--green-100);
  --room-panel-background-disabled: var(--neutral-200);
  --room-panel-background-selected: var(--blue-0);
  --room-panel-border: var(--neutral-300);
  --room-panel-border-selected: var(--blue-400);
  --room-panel-border-success: var(--green-500);
  --room-panel-name-background: var(--blue-500);
  --room-panel-name-background-disabled: var(--neutral-400);
  --room-panel-name-background-success: var(--green-600);
  --room-panel-name-background-selected: var(--blue-400);
  --room-panel-name-text: var(--neutral-100);
  --room-panel-name-text-disabled: var(--neutral-300);
  --room-panel-shadow: var(--neutral-300);
  --room-panel-tooltip: var(--neutral-600);
  --room-panel-tooltip-success: var(--green-700);
  --room-panel-text-success: var(--green-600);
  --room-panel-input-background: var(--neutral-0);
  --room-panel-input-background-disabled: var(--neutral-200);
  --room-panel-input-border: var(--neutral-300);
  --room-panel-input-border-disabled: var(--neutral-300);
  --room-panel-input-border-active-disabled: var(--neutral-300);
  --room-panel-delete: var(--red-500);
  --room-panel-delete-background: var(--neutral-200);
  --room-panel-wrapper-background: var(--neutral-200);
  --room-panel-wrapper-border: var(--neutral-300);

  --icon-color: var(--neutral-600);

  [data-theme="dark"] {
    --surface-base: var(--neutral-900);
    --surface-lower: var(--neutral-1000);
    --surface-top: var(--neutral-1000);
    --surface-top-border: var(--neutral-900);
    --surface-raised: var(--neutral-800);
    --dialog-background: var(--neutral-800);

    --header-background: var(--neutral-900);

    --table-background: var(--neutral-900);
    --table-background-highlight: var(--blue-1000);
    --table-background-holiday: var(--red-1000);
    --table-background-weekend: var(--neutral-950);
    --table-background-ooo: var(--neutral-700);
    --table-header-background: var(--neutral-800);
    --table-header-highlight: var(--blue-700);
    --table-header-holiday: var(--red-700);
    --table-header-weekend: var(--neutral-950);
    --table-header-weekend-background: var(--neutral-950);
    --table-border-color: var(--neutral-1000);

    --table-booking-background: var(--blue-600);
    --table-booking-text: var(--neutral-0);
    --table-booking-border: var(--blue-800);

    --panel-shadow: var(--neutral-1000);

    --primary-button-background: var(--blue-600);
    --primary-button-background-hover: var(--blue-500);
    --primary-button-text: var(--neutral-200);

    --disabled-button-background: var(--neutral-900);
    --disabled-button-text: var(--neutral-500);
    --disabled-button-border: var(--neutral-800);

    --delete-secondary-button-background: none;
    --delete-secondary-button-background-hover: var(--red-500);
    --delete-secondary-button-border: var(--red-500);
    --delete-secondary-button-text: var(--red-500);
    --delete-secondary-button-text-hover: var(--neutral-100);

    --delete-button-background: var(--red-500);
    --delete-button-background-hover: var(--red-400);
    --delete-button-border: var(--red-500);
    --delete-button-text: var(--neutral-100);
    --delete-button-text-hover: var(--neutral-100);

    --secondary-button-background: none;
    --secondary-button-background-hover: var(--blue-500);
    --secondary-button-text: var(--blue-500);
    --secondary-button-text-hover: var(--neutral-100);
    --secondary-button-border: var(--blue-500);

    --text-highlight: var(--neutral-0);
    --text-base: var(--neutral-300);
    --text-secondary: var(--neutral-300);
    --text-muted: var(--neutral-400);
    --text-disabled: var(--neutral-600);
    --text-muted-disabled: var(--neutral-800);

    --input-background: var(--neutral-900);
    --input-background-disabled: transparent;
    --input-background-error: var(--red-900);
    --input-border: var(--neutral-1000);
    --input-border-disabled: var(--neutral-700);

    --admin-header-background: var(--neutral-1000);
    --admin-header-border: var(--neutral-700);
    --admin-background: var(--neutral-900);

    --room-panel-background: var(--neutral-800);
    --room-panel-background-hover: var(--blue-900);
    --room-panel-background-succeed: var(--green-1000);
    --room-panel-background-disabled: var(--neutral-900);
    --room-panel-background-selected: var(--blue-800);
    --room-panel-border: var(--neutral-700);
    --room-panel-border-selected: var(--blue-700);
    --room-panel-border-success: var(--green-900);
    --room-panel-disabled-border: var(--neutral-800);
    --room-panel-name-background: var(--blue-700);
    --room-panel-name-background-disabled: var(--neutral-950);
    --room-panel-name-background-success: var(--green-900);
    --room-panel-name-background-selected: var(--blue-500);
    --room-panel-name-text: var(--neutral-200);
    --room-panel-name-text-disabled: var(--neutral-500);
    --room-panel-shadow: var(--neutral-900);
    --room-panel-tooltip: var(--neutral-700);
    --room-panel-tooltip-success: var(--green-400);
    --room-panel-text-success: var(--green-100);
    --room-panel-input-background: var(--neutral-900);
    --room-panel-input-background-disabled: var(--neutral-800);
    --room-panel-input-border: var(--neutral-800);
    --room-panel-input-border-disabled: var(--neutral-600);
    --room-panel-input-border-active-disabled: var(--neutral-900);
    --room-panel-delete: var(--red-500);
    --room-panel-delete-background: var(--neutral-900);
    --room-panel-wrapper-background: var(--neutral-950);
    --room-panel-wrapper-border: var(--neutral-800);

    --icon-color: var(--neutral-100);
  }
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 20px;
  --mdc-dialog-container-color: var(--dialog-background);
}

button {
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  letter-spacing: 1px;
  border-radius: 12px;
  font-size: 17px;
  height: 40px;
  width: 200px;

  &[disabled] {
    cursor: not-allowed;
  }
}

.primary-button {
  background-color: var(--primary-button-background);
  color: var(--primary-button-text);
  border: 1px solid var(--primary-button-border);

  &:hover {
    background-color: var(--primary-button-background-hover);
    color: var(--primary-button-text);
  }

  &[disabled] {
    background-color: var(--disabled-button-background);
    color: var(--disabled-button-text);
    border: 1px solid var(--disabled-button-border);
  }
}

.secondary-button {
  background-color: var(--secondary-button-background);
  color: var(--secondary-button-text);
  border: 1px solid var(--secondary-button-border);

  &:hover,
  &.active {
    background-color: var(--secondary-button-background-hover);
    color: var(--primary-button-text);
  }
}

.delete-secondary-button {
  background-color: var(--delete-secondary-button-background);
  border: 1px solid var(--delete-secondary-button-border);
  color: var(--delete-secondary-button-text);

  &:hover {
    background-color: var(--delete-secondary-button-background-hover);
    color: var(--delete-secondary-button-text-hover);
  }
}

.delete-button {
  background-color: var(--delete-button-background);
  border: 1px solid var(--delete-button-border);
  color: var(--delete-button-text);

  &:hover {
    background-color: var(--delete-button-background-hover);
    color: var(--delete-button-text-hover);
  }
}

.side-panel-header {
  height: 65px;
  .secondary-button {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.room-panel-wrapper {
  background-color: var(--room-panel-wrapper-background);
  border: 2px solid var(--room-panel-wrapper-border);
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  height: calc(100% - 150px);
  overflow-y: auto;
}

.adult-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/adult.svg");
  mask-image: url("assets/adult.svg");
  width: 29px;
  height: 29px;
}

.child-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/child.svg");
  mask-image: url("assets/child.svg");
  width: 19px;
  height: 19px;
}

.room-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/room.svg");
  mask-image: url("assets/room.svg");
  height: 32px;
  width: 22px;
}

.phone-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/phone.svg");
  mask-image: url("assets/phone.svg");
  height: 20px;
  width: 20px;
}

.email-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/email.svg");
  mask-image: url("assets/email.svg");
  height: 20px;
  width: 20px;
}

.user-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/user.svg");
  mask-image: url("assets/user.svg");
  height: 20px;
  width: 18px;
}

.air-conditioner-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/air-conditioner.svg");
  mask-image: url("assets/air-conditioner.svg");
  height: 24px;
  width: 24px;
}

.wheelchair-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/wheelchair.svg");
  mask-image: url("assets/wheelchair.svg");
  height: 24px;
  width: 24px;
}

.single-bed-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/single-bed.svg");
  mask-image: url("assets/single-bed.svg");
  height: 20px;
  width: 20px;
}

.extra-bed-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/extra-bed.svg");
  mask-image: url("assets/extra-bed.svg");
  height: 20px;
  width: 20px;
}

.double-bed-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/double-bed.svg");
  mask-image: url("assets/double-bed.svg");
  height: 24px;
  width: 24px;
}

.couch-bed-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/couch-bed.svg");
  mask-image: url("assets/couch-bed.svg");
  height: 24px;
  width: 24px;
}

.delete-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/delete.svg");
  mask-image: url("assets/delete.svg");
  height: 24px;
  width: 21px;
}
.ooo-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/ooo.svg");
  mask-image: url("assets/ooo.svg");
  height: 30px;
  width: 30px;
}
.oos-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/oos.svg");
  mask-image: url("assets/oos.svg");
  height: 30px;
  width: 30px;
}
.tax-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/tax.svg");
  mask-image: url("assets/tax.svg");
  height: 30px;
  width: 30px;
}

.food-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/food.svg");
  mask-image: url("assets/food.svg");
  height: 30px;
  width: 30px;
}
.drink-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/drink.svg");
  mask-image: url("assets/drink.svg");
  height: 30px;
  width: 30px;
}
.wellness-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/wellness.svg");
  mask-image: url("assets/wellness.svg");
  height: 30px;
  width: 30px;
}
.other-service-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/other-service.svg");
  mask-image: url("assets/other-service.svg");
  height: 30px;
  width: 30px;
}
.plus-minus-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/plus-minus.svg");
  mask-image: url("assets/plus-minus.svg");
  height: 30px;
  width: 30px;
}
.details-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/details.svg");
  mask-image: url("assets/details.svg");
  height: 30px;
  width: 30px;
}
.booking-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/booking.svg");
  mask-image: url("assets/booking.svg");
  height: 30px;
  width: 30px;
}
.arrive-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/arrive.svg");
  mask-image: url("assets/arrive.svg");
  height: 30px;
  width: 30px;
}
.payment-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/payment.svg");
  mask-image: url("assets/payment.svg");
  height: 30px;
  width: 30px;
}
.depart-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/depart.svg");
  mask-image: url("assets/depart.svg");
  height: 30px;
  width: 30px;
}
.back-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/back.svg");
  mask-image: url("assets/back.svg");
  height: 30px;
  width: 30px;
}
.prepayment-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/prepayment.svg");
  mask-image: url("assets/prepayment.svg");
  height: 30px;
  width: 30px;
}
.tick-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/tick.svg");
  mask-image: url("assets/tick.svg");
  height: 30px;
  width: 30px;
}
.home-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/home.svg");
  mask-image: url("assets/home.svg");
  height: 30px;
  width: 30px;
}
.calendar-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/calendar.svg");
  mask-image: url("assets/calendar.svg");
  height: 30px;
  width: 30px;
}
.admin-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/admin.svg");
  mask-image: url("assets/admin.svg");
  height: 30px;
  width: 30px;
}
.profile-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/profile.svg");
  mask-image: url("assets/profile.svg");
  height: 30px;
  width: 30px;
}
.help-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/help.svg");
  mask-image: url("assets/help.svg");
  height: 30px;
  width: 30px;
}
.scan-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/scan.svg");
  mask-image: url("assets/scan.svg");
  height: 30px;
  width: 30px;
}
.modify-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/modify.svg");
  mask-image: url("assets/modify.svg");
  height: 30px;
  width: 30px;
}
.dark-theme-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/dark-theme.svg");
  mask-image: url("assets/dark-theme.svg");
  height: 30px;
  width: 30px;
}
.success-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/success.svg");
  mask-image: url("assets/success.svg");
  height: 30px;
  width: 30px;
}
.error-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/error.svg");
  mask-image: url("assets/error.svg");
  height: 30px;
  width: 30px;
}
.camera-switch-icon {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("assets/camera-switch.svg");
  mask-image: url("assets/camera-switch.svg");
  height: 30px;
  width: 30px;
}

i {
  display: block;
  background-color: var(--icon-color);
}

.my-snackbar {
  margin: 0 !important;
  position: absolute !important;
  bottom: 8% !important;
}

.clickable {
  pointer-events: all;
}
